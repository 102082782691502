<template>
  <section id="feed">
    <PostsFeed :articles="posts" v-if="posts" :type="type">
      <slot />
    </PostsFeed>
    <feed v-else>
      <slot />
    </feed>
  </section>
</template>

<script>
export default {
  name: 'HomeAbout',
  props: ['posts', 'type'],
  components: {
    Feed: () => import('@/components/Feed'),
    PostsFeed: () => import('@/components/PostsFeed'),
  },
};
</script>
